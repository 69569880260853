// import csm from '../../../images/home/csm.webp';
// import csmhover from '../../../images/home/csmhover.webp';
// import paid from '../../../images/home/paid.webp';
// import paidhover from '../../../images/home/paidhover.webp';
// import ecomm from "../../../images/home/ecomm.webp";
// import ecommhover from "../../../images/home/ecommhover.webp";




export const cardData =[
  {
    img: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yrj5s6.webp",
    title: "Finding Your Perfect Course Match",
    desc: "Confused about which digital marketing specialization to choose from?",
    btnTxt: "Read Here",
    url: `/blog/which-digital-marketing-specialization-should-i-choose/`
  },
  {
    img: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yrlv0m.webp",
    title: "How to Crack Any Interview?",
    desc: "Learn how to use personal storytelling to crack any interview!",
    btnTxt: "Read Here",
    url: "/blog/personal-storytelling-to-crack-interviews/"
  },
  {
    img: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yrqblg.webp",
    title: "Top Trending Fields in Digital Marketing",
    desc: "Ready to tap into the pulse of digital marketing trends?",
    btnTxt: "Read Here",
    url: `/blog/types-of-digital-marketing-fields/`
  }
];