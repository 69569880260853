import React from "react";
import Slider from "react-slick";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Divider from "@mui/material/Divider";
import { reviewData } from "./data";


import "./styles.scss";

function Reviews() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  console.log(reviewData);

  return (
    <section className="ks-live-reviews-section">
      <div className="ks-live-heading-container">
        <h1 className="main-heading">
          Hear what our attendees have said
        </h1>
        <img
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1t5vrsy.webp"
          alt="yellow underline decoration"
          loading="lazy"
          className="yellow-underline-img"
        />
        <h2 className="sub-heading">
          Don't just take our word for it. Here's what our <br/>
          attendees have to say about our webinars:
        </h2>
      </div>
      <div className="stat-box">
        <Stack spacing={1}>
          <h3 className="participant-count">3000+</h3>
          <div className="stat-box-heading">Participants Trained</div>
        </Stack>
        <Divider orientation="vertical" sx={{width: "1px !important"}}/>
        <Stack spacing={1}>
          <div className="rating-div">
            <Rating name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly />
            {" "} (4.5)
          </div>
          <div className="stat-box-heading">Average Rating</div>
        </Stack>
      </div>
      <div className="review-container-desktop">
        {
          reviewData.map(review => {
            return (
              <React.Fragment key={review.name}>
                <ReviewCard review={review} />
              </React.Fragment>
            );
          })
        }
      </div>
      <div className="review-container-mobile">
        <Slider {...settings}>
          {
            reviewData.map(review => {
              return (
                <React.Fragment key={review.name}>
                  <ReviewCard review={review} />
                </React.Fragment>
              );
            })
          }
        </Slider>
      </div>
    </section>
  );
}

const ReviewCard = ({review}) => {
  return (
    <div className="ks-live-review-card">
      <div className="participant-review">
        {review?.description}
      </div>
      <div className="participant-details">
        <b>{review?.name}</b> | {review?.company}
      </div>
    </div>
  );
};

export default Reviews;
